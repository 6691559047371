<template>

    <div>

        <div class="breadcrumb justify-content-end">
            <div class="d-flex  breadcrumb-wrapper pr-1">
                <b-breadcrumb-item :to="{ name: 'dashboard' }">
                    <feather-icon class="align-text-top" icon="HomeIcon" size="15"/>
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('label_google_calendar_init')}}</b-breadcrumb-item>
            </div>
        </div>

        <b-card no-body>

            <b-card-header class="pb-50 mt-2">
                <h5>{{$t('label_google_calendar_init')}}</h5>
            </b-card-header>

            <b-card-body>

                    <b-alert class="mt-2 mb-2" variant="info" show>
                        <div v-if="!googleCalendarId" class="">{{$t('label_google_calendar_init_info') + (defaultCalendarName? ('(' + defaultCalendarName + ')') : '')}}</div>
                        <div v-else class="">{{$t('label_successfully_added') + '(' + googleCalendarId + ')'}}</div>
                    </b-alert>

                    <p :style="{'text-decoration': (activationLink? 'line-through' : 'none')}">{{(locale == 'pl')?'Kliknij link, aby wdrożyć swój' : 'Follow the link to implement your'}} <strong><i><a :style="{cursor: (activationLink? 'default' : 'pointer')}"  @click.prevent="getActivationLink()" href="#">google {{$t('label_calendar')}}</a></i></strong></p>


            </b-card-body>
        </b-card>

    </div>
</template>

<script>

    import {
        BCardText, BCard, BCardHeader, BCardBody,
        BTabs, BTab, BAlert, BButton, BPopover, BAvatar, BPagination,
        BFormGroup, BTable,
        BFormInput, BFormInvalidFeedback, BFormTextarea

    } from 'bootstrap-vue'

    import dynamicItemsBlock from '@src/views/components/dynamicItemsBlock'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import  fileInput from '@/views/components/fileUploadInput'

    export default {

        components: {

            BCardText,
            BCard, BCardHeader, BCardBody,BPagination,
            BTabs, BTab, BAlert, BButton, BPopover, BAvatar,
            BFormGroup,
            BFormInput, BFormInvalidFeedback, BTable,
            dynamicItemsBlock,
            ValidationProvider, ValidationObserver, BFormTextarea,
            fileInput
        },
        props: ['selectOption'],

        data() {
            return {
                PREFIX: 'statement',
                activationLink: '',
                googleCalendarId: false,
                code: '',
                defaultCalendarName: '',
                columnsDefs: [

                    {label: 'label_name', key: 'name'},
                    {label: 'label_date_of_adding', key: 'created_at'},

                ],
            }
        },

        computed:{

            locale: function(){
                return  this.$store.state.appConfig.locale;
            },
            // defaultCalendarName: function(){
            //     // return  this.config('google_calendar_email');
            //     return  this.$store.state.app.general_settings.google_calendar_email;
            // }
        },

        methods: {

            getActivationLink() {

                if(this.activationLink) {
                    return false;
                }
                this.async('get', '/gcalendar', {}, function (resp) {
                    // this.activationLink = resp.data.url;
                    // this.config_json = [];
                    this.redirect(resp.data.url);
                }, false);

            },

        },

        created() {

            let authUser = this.authUser();

            // this.defaultCalendarName = this.config('google_calendar_email');

            this.code = this.$router.currentRoute.query.code;
            let error = this.$router.currentRoute.query.error;

            if(this.code) {

                this.async('get', '/gcalendar', {params: {code: this.code}}, function (resp) {
                    if(resp.data.google_calendar_id) {
                        this.updateAuthUser({google_calendar_status: 'enabled', google_calendar_id: resp.data.google_calendar_id});
                        this.googleCalendarId = resp.data.google_calendar_id;
                        this.$router.push({ name: 'setting_google_calendar', params: {} });
                    } else {
                        this.updateAuthUser({google_calendar_status: 'disabled', google_calendar_id: ''});
                        this.googleCalendarId = false;
                        this.$router.push({ name: 'setting_google_calendar', params: {} });
                    }
                }, function(error){
                    if(authUser.google_calendar_status == 'enabled' && authUser.google_calendar_id) {
                        this.googleCalendarId = authUser.google_calendar_id;
                    }
                    this.$router.push({ name: 'setting_google_calendar', params: {} });
                });


            } else if(error){

                this.updateAuthUser({google_calendar_status: 'disabled', google_calendar_id: ''});
                this.googleCalendarId = false;

            } else if(authUser.google_calendar_status == 'enabled' && authUser.google_calendar_id) {
                this.googleCalendarId = authUser.google_calendar_id;
            }

        }


    }
</script>
